import { NavLink } from "react-router-dom";
import SectionClients1 from "../index2/section-clients1";
import { useTranslation } from "react-i18next";

export default function CartaPorteCallToAction({ _data, clientData }) {
  const { t } = useTranslation();
  return (
    <div
      className="section-full bg-orange-50  tw-estimation-area"
      id="services"
    >
      <div
        className="tw-estimation-section bg-cover bg-no-repeat"
        style={{ backgroundImage: `url(${_data.background})` }}
      >
      </div>
      <div className="container p-t60 p-b120 p-l30 p-r30" id="contact">
        <div className="wt-separator-two-part">
          <div className="row wt-separator-two-part-row">
            <div className="col-xl-6 col-lg-6 col-md-12 wt-separator-two-part-left">
              {/* <!-- TITLE START--> */}
              <div className="section-head left wt-small-separator-outer">
                <div className="wt-small-separator site-text-primary">
                  <div >{t("technology")}</div>
                </div>
                <h2 className="font-semibold text-5xl">{t("technologyTitle")}</h2>
                <p className="section-head-text">{t("technologySubtitle")}</p>
              </div>
              {/* <!-- TITLE END--> */}
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 wt-separator-two-part-right text-right">
              <NavLink
                to="https://app.aduanapp.com?cpg=carta-porte"
                className="btn-half site-button"
              >
                <span>{t("smartSearch")}</span>
                <em></em>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
