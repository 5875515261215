import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

export const createChatMessageBody = conversation => {
  const timestamp = new Date().toLocaleString("es-MX");

  // Create header
  const header = `Chat con Aduanapp - ${timestamp}\n\n`;

  // Format conversation
  const formattedChat = conversation
    .map((msg, index) => {
      const prefix = msg.role === "user" ? "Cliente:" : "Julie A.:";
      return `${prefix} ${msg.content}`;
    })
    .join("\n\n");

  // Combine all parts
  return `${header}${formattedChat}\n\n---\nEnviado desde AduanApp`;
};

export const handleChatEmailClick = conversationData => {
  const to = "notificaciones@aduanapp.mx";
  const subject = `Julius C. Chat - ${new Date().toLocaleString("es-MX")}`;

  const body = createChatMessageBody(conversationData);

  const mailtoLink = `mailto:${to}?subject=${encodeURIComponent(
    subject
  )}&body=${encodeURIComponent(body)}`;
  window.location.href = mailtoLink;
};

export const handleChatWhatsAppClick = conversationData => {
  const phoneNumber = "525638363290";
  const message = createChatMessageBody(conversationData);

  const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
    message
  )}`;
  window.open(whatsappLink, "_blank");
};

export const handleWhatsAppExportClick = conversationData => {
  const phoneNumber = "525638363290";
  const createChatMessageBody = data => {
    const { product, value } = data;

    return `Hola, me gustaría exportar el siguiente producto a Estados Unidos \n \n *Producto:* ${product}\n*Valor:* ${value}`;
  };
  const message = createChatMessageBody(conversationData);

  const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
    message
  )}`;
  window.open(whatsappLink, "_blank");
};
