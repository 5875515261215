import React, { useEffect, useState } from "react";
import { Package2, DollarSign, CheckCircle } from "lucide-react";
import { Dialog, DialogContent } from "./dialog";
import ChatbotAvatar from "../assets/julie-profile.webp";
import { handleWhatsAppExportClick } from "./utils";
import { useTranslation } from "react-i18next";

const ExportForm = () => {
  const [formData, setFormData] = useState({
    product: "",
    value: "",
    // whatsapp: "",
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const { t } = useTranslation();

  const queryParams = new URLSearchParams(window.location.search);
  const isExport = queryParams.get("export");

  useEffect(() => {
    if (isExport && !!setIsOpen) {
      setIsOpen(true);
    }
  }, [isExport]);

  const handleChange = e => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = e => {
    e.preventDefault();
    handleWhatsAppExportClick(formData);
    setIsSubmitted(true);
  };

  useEffect(() => {
    const img = new Image();
    img.src = ChatbotAvatar;
  }, []);

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent className="w-full max-w-[95%] sm:max-w-lg mx-auto bg-white rounded-xl shadow-lg p-4">
        {!isSubmitted ? (
          <>
            <div className="flex items-center space-x-4 mb-6">
              <div className="relative">
                <img
                  src={ChatbotAvatar}
                  loading="eager"
                  decoding="sync"
                  alt="Julie"
                  className="w-16 h-16 rounded-full object-cover"
                />
                <div className="absolute bottom-0 right-0 w-3 h-3 bg-green-500 rounded-full ring-2 ring-white">
                  <div className="absolute w-full h-full rounded-full bg-green-300 animate-ping opacity-75"></div>
                </div>
              </div>
              <div>
                <h2 className="text-2xl font-semibold text-gray-800">
                  {t("exportFormTitle")}
                </h2>
                <p className="text-gray-500">{t("exportFormDescription")}</p>
              </div>
            </div>

            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="space-y-2 group">
                <label
                  htmlFor="product"
                  className="block text-sm font-medium text-gray-700 group-focus-within:text-blue-600 transition-colors duration-200"
                >
                  {t("exportFormQ1")}
                </label>
                <div className="relative">
                  <input
                    type="text"
                    id="product"
                    name="product"
                    value={formData.product}
                    onChange={handleChange}
                    className="w-full py-3 px-10 pl-4 pr-10 border border-gray-300 rounded-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all duration-200 outline-none hover:border-blue-400"
                    placeholder={t("exportFormQ1Placeholder")}
                    required
                  />
                  <div className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400 group-focus-within:text-blue-500">
                    <Package2 size={20} />
                  </div>
                </div>
              </div>

              <div className="space-y-2 group">
                <label
                  htmlFor="value"
                  className="block text-sm font-medium text-gray-700 group-focus-within:text-blue-600 transition-colors duration-200"
                >
                  {t("exportFormQ2")}
                </label>
                <div className="relative">
                  <input
                    type="number"
                    id="value"
                    name="value"
                    value={formData.value}
                    onChange={handleChange}
                    className="w-full py-3 px-10 pl-4 pr-10 border border-gray-300 rounded-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all duration-200 outline-none hover:border-blue-400 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                    placeholder="0.00"
                    min="0"
                    step="0.01"
                    required
                  />
                  <div className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400 group-focus-within:text-blue-500">
                    <DollarSign size={20} />
                  </div>
                </div>
              </div>

              {/* <div className="space-y-2 group">
                <label
                  htmlFor="whatsapp"
                  className="block text-sm font-medium text-gray-700 group-focus-within:text-blue-600 transition-colors duration-200"
                >
                  ¿Cuál es tu número de WhatsApp?
                </label>
                <div className="relative">
                  <input
                    type="tel"
                    id="whatsapp"
                    name="whatsapp"
                    value={formData.whatsapp}
                    onChange={handleChange}
                    className="w-full px-10 pl-4 pr-10 py-3 border border-gray-300 rounded-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all duration-200 outline-none hover:border-blue-400"
                    placeholder="+1 (123) 456-7890"
                    required
                  />
                  <div className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400 group-focus-within:text-blue-500">
                    <MessageCircle size={20} />
                  </div>
                </div>
              </div> */}

              <div className="w-full bg-gray-200 h-1 rounded-full overflow-hidden">
                <div
                  className="bg-green-500 h-full transition-all duration-300"
                  style={{
                    width: `${
                      (Object.values(formData).filter(Boolean).length / 2) * 100
                    }%`,
                  }}
                ></div>
              </div>

              <button
                type="submit"
                className="w-full bg-blue-600 text-white px-6 py-3 rounded-none hover:bg-blue-700 transition-all duration-200 font-medium focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transform hover:-translate-y-0.5"
              >
                {t("exportFormSubmit")}
              </button>
            </form>
          </>
        ) : (
          <div className="text-center py-8">
            <CheckCircle className="w-16 h-16 mx-auto text-green-500 mb-4" />
            <h2 className="text-2xl font-regular text-gray-800 mb-2">
              {t("exportFormThankYou")}
            </h2>
            <p className="text-gray-400 mb-6">
              {t("exportFormThankYouDescription")}
            </p>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ExportForm;
