import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "./dialog";
import { MessageCircle, Mail } from "lucide-react";
import ChatbotAvatar from "../assets/julie-profile.webp";
import { handleChatEmailClick, handleChatWhatsAppClick } from "./utils";

export const Chatbot = () => {
  const [isOpen, setIsOpen] = useState();
  const [showChat, setShowChat] = useState(false);
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const [showContactButtons, setShowContactButtons] = useState(false);
  const messagesEndRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    const img = new Image();
    img.src = ChatbotAvatar;
  }, []);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const queryParams = new URLSearchParams(window.location.search);
  const chat = queryParams.get("chat");

  useEffect(() => {
    if (chat && !!setIsOpen) {
      setIsOpen(true);
    }
  }, [chat]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const sendMessageToAPI = async messageHistory => {
    try {
      const response = await axios.post(
        "https://categorizer-api.onrender.com/chat",
        {
          messages: messageHistory,
        }
      );
      return response.data.text;
    } catch (error) {
      return "";
    }
  };

  const handleOptionSelect = async option => {
    setShowChat(true);
    const newMessage = {
      content: option,
      role: "user",
    };

    setMessages([newMessage]);
    setIsTyping(true);

    const apiResponse = await sendMessageToAPI([newMessage]);

    setMessages(prev => [
      ...prev,
      {
        content: apiResponse,
        role: "assistant",
      },
    ]);

    // Check if the response contains the trigger code
    if (apiResponse.includes("95MMLB")) {
      setShowContactButtons(true);
    }

    setIsTyping(false);
  };

  const handleSendMessage = async e => {
    e.preventDefault();
    if (!inputMessage.trim()) return;
    inputRef.current?.blur();

    const newMessage = {
      content: inputMessage,
      role: "user",
    };

    setMessages(prev => [...prev, newMessage]);
    setInputMessage("");
    setIsTyping(true);

    const apiResponse = await sendMessageToAPI([...messages, newMessage]);

    const cleanResponse = apiResponse.replace("95MMLB", "");

    setMessages(prev => [
      ...prev,
      {
        content: cleanResponse,
        role: "assistant",
      },
    ]);

    if (apiResponse.includes("95MMLB")) {
      setShowContactButtons(true);
    }

    setIsTyping(false);
  };

  const formatContent = content => {
    return content.split("\n").map((line, i) => (
      <React.Fragment key={i}>
        {line}
        {i < content.split("\n").length - 1 && <br />}
      </React.Fragment>
    ));
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <button className="fixed bottom-6 right-6 p-1 rounded-full shadow-lg transition-all duration-200 hover:scale-105 outline-none focus:border-none focus:outline-none z-50 border-1 border-gray-300 bg-white">
          <div className="relative w-14 h-14">
            <img
              src={ChatbotAvatar}
              alt="Chat with Julie"
              className="w-full h-full rounded-full object-cover"
            />
            <div className="absolute bottom-0 right-0 w-3 h-3 bg-green-500 rounded-full ring-2 ring-white">
              <div className="absolute w-full h-full rounded-full bg-green-500 animate-ping opacity-60"></div>
            </div>
          </div>
        </button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-md max-w-[90%] rounded-none p-4 sm:p-8">
        {!showChat ? (
          <>
            <DialogHeader>
              <DialogTitle className="text-lg font-regular">
                👋 Julie A.
                <span className="opacity-30 font-base"> | Aduanapp</span>
              </DialogTitle>
            </DialogHeader>
            <div className="flex flex-col space-y-4 mt-0">
              <div className="flex items-center space-x-3">
                <div className="relative">
                  <img
                    src={ChatbotAvatar}
                    loading="eager"
                    decoding="sync"
                    alt="Julie"
                    className="w-20 h-20 rounded-full"
                  />
                  <div className="absolute bottom-0 right-[10px] w-3 h-3 bg-green-500 rounded-full ring-2 ring-white">
                    <div className="absolute w-full h-full rounded-full bg-green-500 animate-ping opacity-60"></div>
                  </div>
                </div>
                {isTyping ? (
                  <div className="justify-start">
                    <div className="bg-green-500 px-4 py-3 rounded-md flex-1">
                      <div className="flex space-x-2">
                        <div className="w-2 h-2 bg-white rounded-full animate-bounce"></div>
                        <div className="w-2 h-2 bg-white rounded-full animate-bounce delay-100"></div>
                        <div className="w-2 h-2 bg-white rounded-full animate-bounce delay-200"></div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="flex-1 bg-[#0b2f44] rounded-md p-3">
                    <p className=" text-white">
                      ¡Hola! En qué te puedo ayudar?
                    </p>
                  </div>
                )}
              </div>
              {!isTyping && (
                <div className="flex flex-col space-y-2">
                  <button
                    className="w-full text-left px-4 py-2  border bg-gray-100 rounded-md hover:bg-gray-200 transition-colors focus:outline-none"
                    onClick={() =>
                      handleOptionSelect("Quiero importar a México")
                    }
                  >
                    Quiero importar a México
                  </button>
                  <button
                    className="w-full text-left px-4 py-2  border bg-gray-100 rounded-md hover:bg-gray-200 transition-colors focus:outline-none focus:border-none"
                    onClick={() =>
                      handleOptionSelect("Quiero exportar de México")
                    }
                  >
                    Quiero exportar de México
                  </button>
                  <button
                    className="w-full text-left px-4 py-2  border bg-gray-100 rounded-md hover:bg-gray-200 transition-colors focus:outline-none focus:border-none"
                    onClick={() => handleOptionSelect("Tengo una pregunta")}
                  >
                    Otra pregunta
                  </button>
                </div>
              )}
            </div>
          </>
        ) : (
          <div className="flex flex-col h-96 overflow-hidden">
            <DialogHeader>
              <DialogTitle className="text-lg font-semibold flex items-center gap-2 pb-4">
                <div className="relative">
                  <img
                    src={ChatbotAvatar}
                    alt="Julie"
                    className="w-8 h-8 rounded-full"
                  />
                  <div className="absolute bottom-0 right-0 w-2.5 h-2.5 bg-green-500 rounded-full ring-2 ring-white">
                    <div className="absolute w-full h-full rounded-full bg-green-500 animate-ping opacity-60"></div>
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  Julie A.
                  <span className="text-xs text-gray-500 font-normal opacity-75">
                    | Aduanapp
                  </span>
                </div>
              </DialogTitle>
            </DialogHeader>

            <div className="flex-1 overflow-y-auto px-1 py-4 space-y-4 [-ms-overflow-style:none] [scrollbar-width:none] [&::-webkit-scrollbar]:hidden w-full">
              {messages.map((message, index) => (
                <div
                  key={index}
                  className={`flex ${
                    message.role === "user" ? "justify-end" : "justify-start"
                  }`}
                >
                  {message.content && (
                    <div
                      className={`max-w-[80%] px-4 py-2 rounded-md overflow-hidden ${
                        message.role === "user"
                          ? "bg-[#0b2f44] text-white"
                          : "bg-white text-black border border-gray-300"
                      }`}
                    >
                      <p className="break-words">
                        {formatContent(message.content)}
                      </p>
                    </div>
                  )}
                </div>
              ))}
              {isTyping && (
                <div className="flex justify-start">
                  <div className="bg-gray-100 px-4 py-2 rounded-md">
                    <div className="flex space-x-2">
                      <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce"></div>
                      <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce delay-100"></div>
                      <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce delay-200"></div>
                    </div>
                  </div>
                </div>
              )}
              {showContactButtons && (
                <div className="flex flex-row gap-3 mb-4 max-w-full">
                  <button
                    className="flex-1 bg-[#0b2f44] text-white px-4 py-2 rounded-md font-base hover:bg-[#0b2f44] transition-colors flex items-center justify-center border-none focus:outline-none min-w-0 truncate"
                    onClick={() => handleChatWhatsAppClick(messages)}
                  >
                    <MessageCircle className="shrink-0 mr-2" size={20} />
                    <span className="truncate">WhatsApp</span>
                  </button>
                  <button
                    className="flex-1 bg-[#0b2f44] text-white px-4 py-2 rounded-md font-base hover:bg-[#0b2f44] transition-colors flex items-center justify-center border-none focus:outline-none min-w-0 truncate"
                    onClick={() => handleChatEmailClick(messages)}
                  >
                    <Mail className="shrink-0 mr-2" size={20} />
                    <span className="truncate">Email</span>
                  </button>
                </div>
              )}
              <div ref={messagesEndRef} />
            </div>

            {!showContactButtons && (
              <form onSubmit={handleSendMessage} className="mt-4 flex gap-2">
                <input
                  type="text"
                  value={inputMessage}
                  ref={inputRef}
                  onChange={e => setInputMessage(e.target.value)}
                  placeholder="Escribe tu mensaje..."
                  className="min-w-0 flex-1 px-2 sm:px-4 py-2 border rounded-md focus:outline-none focus:ring-none"
                />
                <button
                  type="submit"
                  className="flex-none bg-[#0b2f44] text-white px-4 py-2 rounded-md hover:bg-[#0b2f44] transition-colors"
                >
                  Enviar
                </button>
              </form>
            )}
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};
