import { useState } from "react";
import Banner from "../../sections/common/banner";
import { data as globalsData } from "../../../globals/data/faq";

const AccordionItem = ({ que, ans, index, isOpen, onClick }) => {
  return (
    <div className="mb-2 border border-transparent overflow-hidden">
      <button
        className={`w-full text-left font-normal text-lg md:text-xl p-4 border border-gray-200
          flex justify-between items-center transition-all duration-200
          hover:bg-gray-50 ${isOpen ? "bg-gray-50" : "bg-white"}`}
        onClick={() => onClick(index)}
      >
        <span className="text-gray-900">{que}</span>
        <span
          className={`text-[#0b2f44] transition-transform duration-200 ${
            isOpen ? "rotate-180" : ""
          }`}
        >
          {isOpen ? "−" : "+"}
        </span>
      </button>

      <div
        className={`transition-all duration-200 bg-white
        ${isOpen ? "max-h-96 p-4" : "max-h-0"} overflow-hidden`}
      >
        <p className="text-gray-700 text-lg">{ans}</p>
      </div>
    </div>
  );
};

export default function AccordionPage({ type }) {
  const [openIndex, setOpenIndex] = useState(null);
  const data = globalsData[type];

  const handleClick = index => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <>
      <Banner _data={data.banner} />

      <div className="py-16 md:py-24">
        <div className="container mx-auto px-4">
          <div className="section-head center wt-small-separator-outer">
            <div className="wt-small-separator site-text-primary">
              <div>{data.faq.title}</div>
            </div>
            <h2 className="wt-title text-4xl">{data.faq.subtitle}</h2>
            <p className="section-head-text">{data.faq.description}</p>
          </div>

          <div className="max-w-3xl mx-auto">
            {data.faq.faqs.map((faq, index) => (
              <AccordionItem
                key={index}
                {...faq}
                index={index}
                isOpen={openIndex === index}
                onClick={handleClick}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
